import { FormControl } from '@maestro/components';
import { FieldDefinition } from '@maestro/graphql';
import React from 'react';
import styled from 'styled-components';
import { Hint } from '../../../components/Hint';
import { StoryFieldInput } from './StoryFieldInput';

type Props = {
  allowEmpty: boolean;
  fieldDefinition: FieldDefinition[];
  values: Record<string, string | number>;
  onChange: (field: string, value: string | number) => void;
};

export const StoryForm: React.FC<Props> = (props) => {
  const { onChange, values, fieldDefinition, allowEmpty } = props;

  return (
    <Container>
      {fieldDefinition.map((field) => (
        <FormControl key={field.id} label={field.name}>
          {field.description && <Hint>{field.description}</Hint>}
          <StoryFieldInput
            allowEmpty={allowEmpty}
            field={field}
            value={values[field.id]}
            onChange={(value) => onChange(field.id, value)}
          />
        </FormControl>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.base.dark['1000']};
`;
